<template>
    <div class="app-page">
      <Navbar />
      <div class="main-wrapper">
        <LeftMenu />
        
        <form class="subscription-form">
          <h1>Add Subscription</h1>
          <div>
            <label for="name">Name:</label>
            <input type="text" id="name" name="name" v-model="name" placeholder="Enter subscription name here">
            <label for="description">Description:</label>
            <input type="text" id="description" name="description" v-model="discription" placeholder="Enter subscription description here">
            <label for="amount">Amount:</label>
            <input type="text" id="amount" name="amount" v-model="amount" placeholder="Enter amount here">
            <label for="transactions">transactions:</label>
            <input type="text" id="transactions" name="transactions" v-model="transactions" placeholder="Enter transactions here">
          </div>
          <div class="text-center mt-3">
            <button type="submit" @click="(event) => createSubscription(event)">Create Subscription</button>
          </div>
        </form>
       
      </div>
    </div>
  </template>
  <script>
  import axios from "axios";
  import { mapGetters, mapActions } from "vuex";
  import LeftMenu from "../components/leftmenu/LeftMenu.vue";
  import Navbar from "../components/header/Navbar.vue";
  
  export default {
    name: "CreateSubscriptions",
  
    components: {
      Navbar,
      LeftMenu,
    },
  
    data: function() {
      return {
        showForm: false,
        name: "",
        discription:"",
        amount:"",
        transactions:"",

      };
    },
  
    computed: {
      ...mapGetters("auth", ["user"]),
    },
  
    methods: {
      ...mapActions("auth", ["sendVerifyResendRequest"]),
      gettingValueChanged(value) {
        this.parentMessage = value;
      },

      createSubscription (e) {
        e.preventDefault();
        console.log(e)
            const requestData = {

                name: this.name,
                description: this.discription,
                amount: this.amount,
                transactions: this.transactions
            };
            axios
                .post(process.env.VUE_APP_API_URL + "createSubscriptionPlan", requestData, {

                })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                      console.log(response)

                        this.$router.push("/Subscriptions");
                    }
                })
                .catch((error) => {

                    console.error(error);
            alert('Failed to create subscription plan.');
                });
        },
 



    },
    watch: {

    },
    mounted() {
    },
    created() {},
  };
  </script>
  

  <style scoped>
  .subscription-form {
    background-color: #f5f5f5;
    padding: 20px;
    border: 1px solid #ddd;
    margin: 0 auto;
    border-radius: 15px;
    min-width: 500px;
  }

  .subscription-form h1 {
    margin-bottom: 20px;
    text-align: center;
  }

  .subscription-form label {
    display: block;
    margin-bottom: 5px;
    font-family: var(--outfit);
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #343a40; 
  }

  .subscription-form input {
    width: 100%;
    height: 48px;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    padding-left: 16px;
    border-radius: 5px;
  }

  .subscription-form button {
    background-color: rgb(5, 66, 85);
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    
  }
</style>